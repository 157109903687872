const Home = () => {
	return (
		<>
      <div className="page-header p-3 pb-md-4 mx-auto text-center">
        <h1 className="display-4 fw-normal">AI-cell</h1>
        <p className="fs-5 text-muted">Artificial Immune cell, a tool for prediction of interferon production by peripheral blood monocytes to nucleic acid nanoparticles (NANPs)</p>
      </div>

      <p className="">
        Predictive nucleic acid-encoded platforms rationally designed to communicate with the human immune system will endorse the field of nanomedicine by offering innovative therapeutic strategies. During the course of systematic studies, our research team has established the standard and highly reliable <a href="https://www.nature.com/articles/s41596-020-0393-6">scientific methods</a> to ensure robust and unbiased interpretation of immunological results collected for a large set of representative nucleic acid nanoparticles (NANPs). Based on the transformer architecture, we developed computational models to predict the immune activities of NANPs via construction of an artificial immune cell, called AI-cell. AI-cell can aid in addressing in a timely manner the current critical public health challenges related to overdose and safety of nucleic acid therapies and promote the development of novel biomedical tools.
      </p>

      <br/><br/>
      <img src="banner.png" className="site-image" />

      <br/><br/><br/>
      <p>
        Please <a href="https://onlinelibrary.wiley.com/doi/10.1002/smll.202204941">cite</a> the web application and tool appropriately. We are happy to provide advice and <a href="/contact">technical support</a>.
      </p>

      {/*
      <p>
        Publication: <a href="/dummy-link-publication">link_goes_here<a/>
      </p>
      */}

      <p>
        The complete list of representative NANPs, their physicochemical properties, and relative immune recognitions by PBMCs used to develop the AI-cell predictive models are provided <a href="NANP_QSAR_database.xlsx" download>here</a>. 
      </p>
      <p>
        The related publications that contain the experimental results that have been used to develop this tool can be found <a href="/references">here</a>.
      </p>
      <p>
        Funding information for AI-cell is provided <a href="/funding">here</a>.
      </p>
		</>
	);
};

export default Home;
