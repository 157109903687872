const Funding = () => {
	return (
		<>
			<h5 className="mt-4 mb-3">AI-Cell Funding</h5>
			<p>
				The research effort for the development of this tool was supported by the National Institute of General Medical Sciences of the National Institutes of Health under Award Numbers R01GM120487 and R35GM139587 (to Dr. Kirill Afonin). The content is solely the responsibility of the authors and does not necessarily represent the official views of the National Institutes of Health. The study was also funded in part by federal funds from the National Cancer Institute, National Institutes of Health, under contract 75N91019D00024 (Dr. Marina Dobrovolskaia and Dr. Enping Hong). This research was supported in part by the Intramural/Extramural research program of the NCATS, NIH. The content of this publication does not necessarily reflect the views or policies of the Department of Health and Human Services, nor does mention of trade names, commercial products, or organizations imply endorsement by the U.S. Government.
			</p>
		</>
	);
};

export default Funding;
