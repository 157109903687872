import { useState } from "react";
import StrandInputBlock from "../components/StrandInputBlock";
const axios = require('axios').default;

const PREDICT_API_ENDPOINT = `${process.env.REACT_APP_API_BASE_URI}/predict`;
const DEFAULT_NO_OF_STRANDS = process.env.REACT_APP_DEFAULT_NO_OF_STRANDS;

const getBlankStandListOfLength = (length, startingIndex = 1) => {
  const list = [];
  for (let i = 0; i < length; i++) {
    list.push({
      Strand_Name: `Strand${startingIndex + i}`,
      sequence: '' 
    });
  }

  return list;
};


const Predict = () => {
	const [totalStrands, setTotalStrands] = useState(DEFAULT_NO_OF_STRANDS);

  const [result, setResult] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [strands, setStrands] = useState(getBlankStandListOfLength(DEFAULT_NO_OF_STRANDS));

	const doPredict = () => {
		axios.post(PREDICT_API_ENDPOINT, strands).then(response => {
      console.log("response", response);
      setResult(response.data);
      console.log(response?.data?.Prediction);
      setSuccess(response?.data?.Prediction === 'Success');
    });
	}

  const onChangeStandNumber = (count) => {
    setTotalStrands(count);
    
    if (count < strands.length) {
      setStrands([...strands].slice(0, count));
    } else {
      setStrands([...strands, ...getBlankStandListOfLength(count - strands.length, strands.length + 1)])
    }
  }

	const renderStrandBlocks = () => {
    return strands.map((strand, i) =>
      <StrandInputBlock
        index={i}
        key={i}
        value={strand}
        onChange={(val) => setStrandValueAtIndex(i, val)}
      />
    )
	};

  const setStrandValueAtIndex = (index, strand) => {
    const newStrandList = [...strands];
    newStrandList[index] = strand;
    setStrands(newStrandList);
  };

	return (
		<>
			<div className="d-flex align-items-center my-3">
				<p className="text-muted mb-0 me-2">Number of strands:</p>
				<select
          value={totalStrands}
					className="form-select form-select"
					onChange={(e) => onChangeStandNumber(e?.target?.value)}
					style={{ width: "100px" }}
				>
					<option value="1">1</option>
					<option value="2">2</option>
					<option value="3">3</option>
					<option value="4">4</option>
					<option value="5">5</option>
					<option value="6">6</option>
					<option value="7">7</option>
					<option value="8">8</option>
				</select>
			</div>

			<div className="p-3 mb-3 border">
				<h4 className="text-secondary">Input Sequences</h4>
				  { renderStrandBlocks() }
				<div className="mt-2 text-end">
					<button className="btn btn-primary text-white trigger-predict-btn" onClick={doPredict}>Predict</button>
				</div>
			</div>

			<div className="p-3 border">
				<h4 className="text-secondary mb-3">Prediction Results: <span className={isSuccess ? 'text-success' : 'text-danger'}>{result.Prediction || 'N.A'}</span></h4>
				<div className="row row-cols-2 gy-2">
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-α Activity (pg/ml):</div>
							<div className="value">{result['IFN-a_activity'] || 'N.A'}</div>
						</div>
					</div>
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-β Activity (pg/ml):</div>
							<div className="value">{result['IFN-b_activity'] || 'N.A'}</div>
						</div>
					</div>
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-λ Activity (pg/ml):</div>
							<div className="value">{result['IFN-h_activity'] || 'N.A'}</div>
						</div>
					</div>
					<div className="col">
						<div className="d-flex">
							<div className="title fw-bold me-2">IFN-ω Activity (pg/ml):</div>
							<div className="value">{result['IFN-w_activity'] || 'N.A'}</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Predict;
