const StrandInputBlock = (props) => {

  const onNameChange = (name) => {
    props.onChange({
      Strand_Name: name,
      sequence: props.value.sequence,
    });
  }

  const onSequenceChange = (sequence) => {
    props.onChange({
      Strand_Name: props.value.Strand_Name,
      sequence: sequence,
    });
  }

	return (
		<div className="row g-2 bg-light p-2 mt-0 mb-4 border-bottom">
			<div className="col-3">
				<input
					type="text"
					className="form-control form-control-sm"
					value={props.value.Strand_Name}
					onChange={(e) => onNameChange(e.target.value)}
				/>
			</div>
			<div className="col-9">
				<textarea
          rows="3"
          className="form-control form-control-sm"
          placeholder="NANP sequence"
          value={props.value.sequence}
          onChange={(e) => onSequenceChange(e.target.value)}>
        </textarea>
			</div>
		</div>
	);
};

export default StrandInputBlock;
