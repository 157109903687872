import ContactBlock from "../components/ContactBlock";

const Contact = () => {
	return (
		<>
			<h5 className="mt-4 mb-3">Machine learning related queries:</h5>
			<ContactBlock
				avatar="SJ.png"
				name="Dr. Sankalp Jain"
				institute="National Center for Advancing Translational Sciences (NIH)"
				email="sankalp.jain@nih.gov"
			/>
			<ContactBlock
				avatar="AZ.png"
				name="Dr. Alexey Zakharov"
				institute="National Center for Advancing Translational Sciences (NIH)"
				email="alexey.zakharov@nih.gov"
			/>

			<h5 className="mt-4 mb-3">Immunology related queries:</h5>
			<ContactBlock
				avatar="MD.png"
				name="Dr. Marina Dobrovolskaia"
				institute="Frederick National Laboratory for Cancer Research (NIH)"
				email="marina@mail.nih.gov"
			/>
			<ContactBlock
				avatar="EH.png"
				name="Dr. Enping Hong"
				institute="Frederick National Laboratory for Cancer Research (NIH)"
				email=""
			/>


			<h5 className="mt-4 mb-3">NANP design and characterization related queries:</h5>
			<ContactBlock
				avatar="KA.png"
				name="Dr. Kirill Afonin"
				institute="University of North Carolina at Charlotte"
				email="kafonin@uncc.edu"
			/>
			<ContactBlock
				avatar="MC.png"
				name="Dr. Morgan Chandler"
				institute="University of North Carolina at Charlotte"
				email=""
			/>		
			<ContactBlock
				avatar="JH.png"
				name="Dr. Justin Halman"
				institute="University of North Carolina at Charlotte"
				email=""
			/>
		</>
	);
};

export default Contact;
