import Reference from "../components/Reference";

const referencesJson = [
  {
    id: 1,
    title: "Functionally-interdependent shape-switching nanoparticles with controllable properties",
    contributors: "Halman JR, Satterwhite E, Roark B, Chandler M, Viard M, Ivanina A, Bindewald E, Kasprzak WK, Panigaj M, Bui MN, Lu JS, Miller J, Khisamutdinov EF, Shapiro BA, Dobrovolskaia MA, Afonin KA",
    journalName: "Nucleic Acids Research, 45(4): 2210-2220, 2017.",
    doiName: "10.1093/nar/gkx008",
    doiLink: "https://academic.oup.com/nar/article/45/4/2210/2929528?login=false",
  },
  {
    id: 2,
    title: "Programmable nucleic acid-based polygons with controlled neuroimmunomodulatory properties for predictive QSAR modeling",
    contributors: "Johnson MB, Halman JR, Satterwhite E, Zakharov AV, Bui MN, Benkato K, Goldsworthy V, Kim TJ, Hong E, Dobrovolskaia MA, Khisamutdinov E, Marriott I, Afonin KA",
    journalName: "Small, 13(42), 2017.",
    doiName: "10.1002/smll.201701255",
    doiLink: "https://onlinelibrary.wiley.com/doi/abs/10.1002/smll.201701255"
  },
  {
    id: 3,
    title: "Dynamic behavior of RNA nanoparticles analyzed by AFM on a mica/air interface",
    contributors: "Sajja S, Chandler M, Federov D, Kasprzak WK, Lushnikov AY, Viard M, Shah A, Dang D, Dahl J, Worku B, Dobrovolskaia MA, Krasnoslobodtsev A, Shapiro BA, Afonin KA",
    journalName: "Langmuir, 34(49): 15099-15108, 2018.",
    doiName: "10.1021/acs.langmuir.8b00105",
    doiLink: "https://pubs.acs.org/doi/10.1021/acs.langmuir.8b00105"
  },
  {
    id: 4,
    title: "Structure and composition define immunorecognition of nucleic acid nanoparticles",
    contributors: "Hong E, Halman J, Shah A, Khisamutdinov EF, Dobrovolskaia MA, Afonin KA",
    journalName: "Nano Letters, 18(7): 4309-4321, 2018.",
    doiName: "10.1021/acs.nanolett.8b01283",
    doiLink: "https://pubs.acs.org/doi/10.1021/acs.nanolett.8b01283"
  },
  {
    id: 5,
    title: "RNA fibers as optimized nanoscaffolds for siRNA coordination and reduced immunological recognition",
    contributors: "Rackley L, Stewart JM, Salotti J, Krokhotin A, Shah A, Halman JR, Juneja R, Smollett J, Lee L, Roark K, Viard M, Tarannum M, Vivero-Escoto J, Johnson PF, Dobrovolskaia MA, Dokholyan NV, Franco E, Afonin KA",
    journalName: "Advanced Functional Materials, 28: 1805959, 2018.",
    doiName: "10.1002/adfm.201805959",
    doiLink: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6599627/"
  },
  {
    id: 6,
    title: "RNA-DNA fibers and polygons with controlled immunorecognition activate RNAi, FRET, and transcriptional regulation of NF-κB in human cells",
    contributors: "Ke W, Hong E, Saito RF, Rangel MC, Wang J, Viard M, Richardson M, Khisamutdinov EF, Panigaj M, Dokholyan NV, Chammas R, Dobrovolskaia MA, Afonin KA",
    journalName: "Nucleic Acids Research, 47(3): 1350-1361, 2019.",
    doiName: "10.1093/nar/gky1215",
    doiLink: "https://academic.oup.com/nar/article-abstract/47/3/1350/5229211?redirectedFrom=fulltext"
  },
  {
    id: 7,
    title: "Toll-Like Receptor-Mediated Recognition of Nucleic Acid Nanoparticles (NANPs) in Human Primary Blood Cells",
    contributors: "Hong E, Halman JR, Shah A, Cedrone E, Truong N, Afonin KA, Dobrovolskaia MA",
    journalName: "Molecules, 24(6), 2019.",
    doiName: "10.3390/molecules24061094",
    doiLink: "https://www.mdpi.com/1420-3049/24/6/1094"
  },
  {
    id: 8,
    title: "Induction of cytokines by Nucleic Acid Nanoparticles (NANPs) depends on the type of delivery carrier",
    contributors: "Avila YI, Chandler M, Cedrone E, Newton HS, Richardson M, Xu J, Clogston J, Liptrott N, Afonin KA, and Dobrovolskaia MA",
    journalName: "Molecules, 26(3): 652, 2020.",
    doiName: "10.3390/molecules26030652",
    doiLink: "https://www.mdpi.com/1420-3049/26/3/652"
  },
  {
    id: 9,
    title: "Anhydrous Nucleic Acid Nanoparticles for Storage and Handling at Broad Range of Temperatures",
    contributors: "Tran AN, Chandler M, Halman J, Beasock D, Fessler A, McKeough RQ, Lam PA, Furr DP, Wang J, Cedrone E, Dobrovolskaia MA, Dokholyan NV, Trammell SR, Afonin KA",
    journalName: "Small, 2021.",
    doiName: "10.1002/smll.202104814",
    doiLink: "https://pubmed.ncbi.nlm.nih.gov/35128787/"
  },
];

const References = () => {
	return (
		<>
			<h5 className="mt-4 mb-4">The following publications contain the experimental results that have been used to develop this tool</h5>
			<div className="references-container">
				{
					referencesJson.map(ref => 
						<Reference
							key={ref.id}
							title={ref.title}
							contributors={ref.contributors}
							journalName={ref.journalName}
							doiName={ref.doiName}
							doiLink={ref.doiLink}
						/>
					)
				}
			</div>
		</>
	);
};

export default References;
