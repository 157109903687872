const ContactBlock = ({ avatar, name, role, institute, email }) => {
	return (
		<div className="d-flex align-items-center mb-3 ms-5" key={Math.random()}>
			<div className="me-3">
				<img src={avatar} alt="" style={{ maxWidth: "120px" }} />
			</div>
			<div className="">
				<p className="lead mb-0">{name}</p>
				<p className="text-muted mb-1">{role}</p>
				<p className="text-muted mb-0">{institute}</p>
				<p className="text-muted mb-0">
					<a href={`mailto: ${email}`}>{email}</a>
				</p>
			</div>
		</div>
	);
};

export default ContactBlock;
