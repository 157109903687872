const Reference = ({ title, contributors, journalName, doiName, doiLink }) => {
	return (
    <div className="mb-4 pb-4 border-bottom border-bottom-1">
      <h6>{title}</h6>
      <div>{contributors}</div>
      <div className="d-flex">
        <div className="text-muted me-1">
          {journalName}
        </div>
        <div>
          <i>
            <a href={doiLink}>(doi: {doiName})</a>
          </i>
        </div>
      </div>
    </div>
	);
};

export default Reference;
